.svg-icon {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);

  &--white {
    fill: $colour-white;
  }

  &--path-white {
    path {
      fill: $colour-white;
    }
  }

  &--blue {
    fill: $colour-neon-blue;
  }

  &--red {
    fill: #ff3c00;
  }

  &--pink {
    fill: #ffe6e5;
  }

  &--dark-tan {
    fill: $colour-dark-tan;
  }

  &--stroke-dark {
    stroke-width: 1.5;

    path {
      stroke: #2f1f19;
    }

    &-tan {
      path {
        stroke: #ca1016;
      }
    }
  }

  &--indent {
    margin-right: 5px;

    &--large {
      margin-right: 10px;
    }
  }

  &--brown {
    fill: $colour-heathered-grey;
  }

  &--dark {
    fill: $colour-cocoa-brown;
  }

  &--darkred {
    fill: #a4243b;
  }

  &--icon-color-change {
    path {
      fill: inherit;
    }

    rect[stroke],
    path[stroke] {
      stroke: inherit;
    }
  }
}
