$rotate-icon: '/static/images/new_ad/icons/icon-rotate.svg';
$delete-icon: '/static/images/new_ad/icons/icon-delete-photo.svg';
$background-color: #f8f8f8;

.item-modal {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  position: fixed;
  background-color: transparent;

  &.closed {
    animation: hideToBottom 0.3s forwards ease-in-out;
  }

  &.opened {
    animation: showFromBottom 0.3s forwards ease-in-out;
  }

  &__content {
    background-color: $background-color !important;
    overflow-y: scroll;
    height: 99.9vh;
  }

  &__block-header {
    color: $colour-cocoa-brown;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  &__field-header {
    color: $colour-cocoa-brown;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__rooms-content {
    padding: 0 15px 39px;
    overflow-y: auto;
    max-height: 74vh;
    -webkit-overflow-scrolling: touch;
  }

  &__rooms-modal {
    max-height: 85vh;
    width: 100%;
    background-color: $colour-white;
    border-radius: 7px 7px 0 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  }

  &__error-text {
    color: $colour-red;
    font-size: 14px;
    font-weight: 400;
  }

  &__button {
    $button: &;
    width: calc(100% - 30px);
    height: 50px;
    border-radius: 14px;
    display: block;
    margin: 15px 15px 10px;
    font-size: 16px;
    text-align: center;
    color: $colour-white;
    background-color: $colour-neon-blue;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 55px;
    font-size: 15px;
    color: $colour-cocoa-brown;
  }

  &__owner_type {
    display: flex;
    text-align: center;
    padding-inline: 5px;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid $colour-grey-wild-sand;
    height: 38px;
    font-size: 15px;
    color: $colour-cocoa-brown;
    margin-bottom: 15px;

    &-active {
      background-color: $colour-light-blue;
      border-color: $colour-neon-blue;
      color: $colour-neon-blue;
    }
  }

  &__option {
    display: flex;
    width: 50%;
    -webkit-tap-highlight-color: transparent;
    outline: none;

    &:first-child .item-modal__owner_type {
      border-radius: 50px 0 0 50px;
    }

    &:last-child .item-modal__owner_type {
      border-radius: 0 50px 50px 0;
    }
  }

  &__disclaimer {
    margin-bottom: -5px;
    margin-top: 10px;
    font-size: 12px;
    color: $colour-green;
    width: 100%;
  }

  &__terms {
    margin: 0 15px 44px;
    text-align: center;
    font-size: 12px;
    color: $colour-heathered-grey;
  }

  &__link {
    font-size: 12px;
    color: $colour-heathered-grey;
  }

  &__auth {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    border: 0;
    z-index: 1000;
  }

  &__validation {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;

    &__counter {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 10px;
      color: $colour-heathered-grey;
    }
  }

  &__type-deal {
    margin-top: 60px;
    padding: 0 15px;
    background-color: $colour-white;
  }

  &__photo {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    object-fit: cover;

    &-upload {
      width: 100%;
      height: 105px;
      background-color: $colour-grey-desert-storm;
      border-radius: 7px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &--has-images {
        height: 50px;
        margin-top: 10px;
      }
    }

    &-icon {
      position: absolute;
      width: 25px;
      height: 25px;
      background-size: cover;
      cursor: pointer;
      margin: 5px;

      &--delete {
        top: 0;
        right: 0;
        background-image: url($delete-icon);
      }

      &--rotate-right {
        bottom: 0;
        left: 0;
        background-image: url($rotate-icon);
      }

      &--rotate-left {
        bottom: 0;
        right: 0;
        transform: scale(-1, 1);
        background-image: url($rotate-icon);
      }
    }

    &-text {
      margin-left: 5px;
      color: $colour-neon-blue;
      font-size: 15px;
      font-weight: 400;
    }

    &-card {
      border-radius: 7px;
      justify-self: center;
      position: relative;
      width: 100%;
      display: flex;
      aspect-ratio: 1;
      background-size: cover;
      background-position: center;
      user-select: none !important;
      -webkit-touch-callout: none !important;
    }

    &-wrapper {
      display: grid;
      gap: 10px 15px;
      height: fit-content;
      grid-template-columns: repeat(auto-fill,
      minmax(calc((100% / 3) - 10px), 1fr));
    }

    &-loader {
      width: 100%;
      aspect-ratio: 1;
      justify-self: center;
      position: relative;
    }
  }
}
